









































import Vue from 'vue';
import {
  ICategory,
  IPostsBlogFields,
  ITags,
} from '../../@types/generated/contentful';

import Article from '../components/Article.vue';

export default Vue.extend({
  name: 'Blog',
  components: {
    Article,
  },
  data() {
    return {
      posts: [] as IPostsBlogFields[],
      categories: [] as ICategory[],
      currentTags: [] as ITags[], // Store the tags for the selected category
      selectedCategory: '', // Track selected category
      filteredPosts: [] as IPostsBlogFields[],
      currentCategory: '' as string,
      selectedTopicIndex: null as number | null,
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      try {
        const [posts, categories] = await Promise.all([
          this.getPosts(),
          this.getCategories(),
        ]);

        this.posts = posts;
        this.filteredPosts = posts;
        this.categories = categories;
        // console.log('Posts:', posts, 'Categories:', categories);
      } catch (error) {
        console.error('Error fetching content', error);
      }
    },
    async getPosts(): Promise<IPostsBlogFields[]> {
      try {
        const data = await this.$contentful.getEntry<{
          posts: IPostsBlogFields[];
        }>('CPXA5yGOgKNzgYEd59PwM');
        return data.fields.posts; // Now it returns an array of IPostsBlogFields
      } catch (err) {
        console.error('Error fetching posts:', err);
        throw err;
      }
    },
    async getCategories(): Promise<ICategory[]> {
      try {
        const data = await this.$contentful.getEntry<{ category: ICategory[] }>(
          '2LZg6ib65yW4dmP4z42afX',
        );
        return data.fields.category || []; // Ensure it returns an array of ICategory
      } catch (err) {
        console.error('Error fetching categories:', err);
        throw err;
      }
    },
    // Fetch topics when a category is clicked
    async fetchCategoryTopics(category: ICategory, index: number) {
      console.log(category.fields.category, this.currentCategory);
      if (category.fields.category === this.currentCategory) {
        this.resetSelection();
        return;
      }
      this.selectedTopicIndex = index; // Update selected topic index
      try {
        const tagId: any = category.fields.categoryTags;

        // Fetch tags using the tag IDs
        const tags = await this.getTagsById(tagId);
        this.currentTags = tags;
        this.selectedCategory = category.fields.category; // Set the selected category
        this, (this.currentCategory = category.fields.category);
        console.log(this.selectedCategory);
        this.filterPost();
      } catch (err) {
        console.error('Error fetching category tags:', err);
      }
    },
    async getTagsById(tagId: ITags): Promise<ITags[]> {
      try {
        const data = await this.$contentful.getEntry<ITags>(tagId.sys.id);
        // console.log(data);
        return data.fields.tags;
      } catch (err) {
        console.error('Error fetching tags:', err);
        throw err;
      }
    },
    filterPost(): IPostsBlogFields[] {
      if (!this.selectedCategory) {
        return this.posts; // Return all posts if no category is selected
      }
      const tmp = [];
      for (const post of this.posts) {
        if (post.fields.postCategory === this.selectedCategory) {
          tmp.push(post);
        }
      }
      return (this.filteredPosts = tmp);
    },
    resetSelection(): any {
      this.selectedCategory = '';
      this.currentCategory = '';
      this.currentTags = [];
      this.selectedTopicIndex = null;
      this.filteredPosts = this.posts;
    },
  },
});
