










































import Vue from 'vue';
import router from '@/router';
import { IPostFields } from '../../@types/generated/contentful';

export default Vue.extend({
  name: 'Article',
  props: {
    postRef: String,
  },
  data() {
    return {
      loading: true,
      post: ({
        title: '',
        content: {
          content: [],
          data: {},
          nodeType: 'document',
        },
        postDate: '',
        read: '',
        tags: {
          fields: {
            tags: [],
          },
        },
      } as unknown) as IPostFields,
    };
  },
  mounted() {
    this.getPost(this.postRef);
  },
  methods: {
    goToPostView(id: string) {
      router.push({ name: 'post', params: { postId: id } });
    },
    async getPost(id: string) {
      this.loading = true;
      try {
        const data = await this.$contentful.getEntry<IPostFields>(id);
        this.post = data.fields;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
